import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import GalleryGridFancy from '../../components/galleryGridFancy'
import logoRo from "../../images/rollovers/graphic-design/logo-ro.png"
import "../layout.css"

import img1 from '../../images/graphic-design/logos/1.jpg'
import img2 from '../../images/graphic-design/logos/2.jpg'
import img3 from '../../images/graphic-design/logos/3.jpg'
import img4 from '../../images/graphic-design/logos/4.jpg'
import img5 from '../../images/graphic-design/logos/5.jpg'
import img6 from '../../images/graphic-design/logos/6.jpg'
import img7 from '../../images/graphic-design/logos/7.jpg'
import img8 from '../../images/graphic-design/logos/8.jpg'
import img9 from '../../images/graphic-design/logos/9.jpg'
import img10 from '../../images/graphic-design/logos/10.jpg'
import img11 from '../../images/graphic-design/logos/11.jpg'
import img12 from '../../images/graphic-design/logos/12.jpg'

export default function Logos() {
    let images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12]
    return (
        <GalleryBackground title="Logos" imgSrc={logoRo}>
            <GalleryGridFancy imgArray={images} />
        </GalleryBackground>
    )
}